import { forwardRef, RefObject, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { getLocale } from "@/helpers/i18n"
import { cn } from "@/lib/utils"

import { ScrollArea } from "./scroll-area"

type Time = { hours: number; minutes: number }

type Props = {
  className?: string
  isDisabled?: boolean
  maxDate?: Date
  minDate?: Date
  onBlur?: VoidFunction
  onChange: (date: Date | null) => void
  onFocus?: VoidFunction
  placeholder?: string
  value: Date | null
}

/**
 * Combine the Calendar component with a home-made "time picker" component
 * to let users pick a date and time, used to schedule calls in the Call Report form.
 * Trigger onChange and close the popover only when a date AND time are selected.
 **/

export const DateAndTimePicker = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      isDisabled,
      maxDate,
      minDate,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      value,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const locale = getLocale()

    const [selectedDate, setSelectedDate] = useState<Date | null>(value)
    const [selectedTime, setSelectedTime] = useState<Time | null>(
      getDefaultTime(value),
    )
    const [isOpen, setIsOpen] = useState(false)

    const availableTimes = getAvailableTimes({
      maxDate,
      minDate,
      selectedDate,
    })

    const handleSelectDate = (date: Date | undefined) => {
      if (!date) return
      setSelectedDate(date)
      if (selectedTime) {
        date.setHours(selectedTime.hours, selectedTime.minutes)
        onChange(date)
        setIsOpen(false)
      }
    }

    const handleSelectTime = (time: Time) => {
      setSelectedTime(time)
      if (!selectedDate) return
      const newDate = new Date(selectedDate)
      newDate.setHours(time.hours, time.minutes)
      onChange(newDate)
      setIsOpen(false)
    }

    // The focus and blur events for the datetime picker component are handled by the popover content
    // component via onOpenAutoFocus and onCloseAutoFocus props. On focus we must clear any validation errors
    // and on blur we must validate the field.
    const onPopoverContentFocus = () => {
      if (onFocus) {
        onFocus()
      }
    }

    const onPopoverContentBlur = () => {
      if (onBlur) {
        onBlur()
      }
    }

    return (
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            disabled={isDisabled}
            variant={"outline"}
            className={cn(
              "disabled:opacity-1 w-[320px] justify-between border-input text-left disabled:bg-neutral-200 disabled:text-neutral-400",
              !value && "text-muted-foreground",
              className,
            )}
          >
            <span className="flex flex-1 justify-start">
              {selectedDate
                ? format(selectedDate, "PPP", { locale }) +
                  " " +
                  formatTime(selectedTime)
                : placeholder || t("global.datepicker.placeholder")}
            </span>
            <CalendarIcon className="size-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          onOpenAutoFocus={onPopoverContentFocus}
          onCloseAutoFocus={onPopoverContentBlur}
        >
          <div className="flex w-full gap-4">
            <Calendar
              locale={locale}
              mode="single"
              selected={selectedDate || undefined}
              showOutsideDays
              onSelect={handleSelectDate}
              initialFocus
              fromDate={minDate}
              toDate={maxDate}
            />
            <TimePicker
              availableTimes={availableTimes}
              onChange={handleSelectTime}
              value={selectedTime}
              isOpen={isOpen}
            />
          </div>
        </PopoverContent>
      </Popover>
    )
  },
)

DateAndTimePicker.displayName = "DateAndTimePicker"

function TimePicker({
  availableTimes,
  isOpen,
  onChange,
  value,
}: {
  availableTimes: Time[]
  isOpen: boolean
  onChange: (time: Time) => void
  value: Time | null
}) {
  const { t } = useTranslation()
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  useScrollToSelectedTime({ availableTimes, isOpen, scrollAreaRef, value })

  return (
    <div className="border-l">
      <div className="flex items-center justify-center border-b py-4 text-sm">
        {t("global.datepicker.timeLabel")}
      </div>
      <ScrollArea className="h-[300px]" ref={scrollAreaRef}>
        <div className="py-2 pl-2 pr-4">
          {availableTimes.map((time) => (
            <div
              key={time.hours + "/" + time.minutes}
              className={cn(
                "cursor-pointer rounded px-8 py-1",
                value && isSameTime(time, value)
                  ? "bg-primary-400 text-neutral-100"
                  : "hover:bold hover:bg-primary-100",
              )}
              onClick={() => onChange(time)}
            >
              {formatTime(time)}
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}

function getDefaultTime(value: Date | null): Time | null {
  if (!value) return null
  const hours = value.getHours()
  const minutes = value.getMinutes()

  return { hours, minutes: minutes >= 30 ? 30 : 0 }
}

function useScrollToSelectedTime({
  availableTimes,
  isOpen,
  scrollAreaRef,
  value,
}: {
  availableTimes: Time[]
  isOpen: boolean
  scrollAreaRef: RefObject<HTMLDivElement>
  value: Time | null
}) {
  const ITEM_HEIGHT = 32

  return useLayoutEffect(() => {
    if (isOpen && value && scrollAreaRef.current) {
      const selectedIndex = availableTimes.findIndex((time) =>
        isSameTime(time, value),
      )
      const scrollPosition = selectedIndex * ITEM_HEIGHT
      scrollAreaRef.current.scrollTo(0, scrollPosition)
    }
  })
}

function formatTime(time?: Time | null) {
  if (!time) return "--:--"

  return (
    time.hours.toString().padStart(2, "0") +
    ":" +
    time.minutes.toString().padStart(2, "0")
  )
}

function isSameTime(a: Time, b: Time) {
  return a.hours === b.hours && a.minutes === b.minutes
}

function getAvailableTimes({
  maxDate,
  minDate,
  selectedDate,
}: Pick<Props, "maxDate" | "minDate"> & {
  selectedDate: Date | null
}) {
  const allTimes = Array.from({ length: 24 * 2 }, (_, i) => {
    const hours = Math.floor(i / 2)
    const minutes = i % 2 === 0 ? 0 : 30

    return { hours, minutes }
  })

  const timeDate = selectedDate ?? new Date()

  if (minDate && maxDate) {
    return allTimes.filter((time) => {
      timeDate.setHours(time.hours, time.minutes)

      return timeDate >= minDate && timeDate <= maxDate
    })
  }

  if (minDate) {
    return allTimes.filter((time) => {
      timeDate.setHours(time.hours, time.minutes)

      return timeDate >= minDate
    })
  }

  if (maxDate) {
    return allTimes.filter((time) => {
      timeDate.setHours(time.hours, time.minutes)

      return timeDate <= maxDate
    })
  }

  return allTimes
}

import { statusMap } from "@repo/core/models"
import { z } from "zod"

import { PaginationParams } from "@/components/pagination/pagination-state"
import { resolveLanguage } from "@/helpers/i18n"
import { useQueryAPI } from "@/hooks/query-api"
import { SortOptions } from "@/hooks/table-sorting"

import { FilterSelections } from "../campaigns-types"

/**
 * The base schema for students returned by both Campaign Details and Campaign Preview APIs
 * with the common attributes,  we `extend` it later.
 */
const studentBaseSchema = z.object({
  first_name: z.string(),
  gender: z.string(),
  last_name: z.string(),
  phone_number: z.string(),
  university: z.string(),
})

const campaignStudentSchema = studentBaseSchema.extend({
  comment: z.string().nullable().optional(),
  contact_count: z.number().nullable(),
  current_status: z.nativeEnum(statusMap),
  latest_status_action_taken: z.nativeEnum(statusMap).nullable(),
  email: z.string().nullable().optional(),
  latest_contacted_at: z.string().nullable(),
  student_id: z.string(),
})

export type CampaignStudent = z.infer<typeof campaignStudentSchema>

const previewStudentSchema = studentBaseSchema.extend({
  _id: z.string(),
  first_name_kana: z.string(),
  last_name_kana: z.string(),
  email: z.string(),
  major: z.string(),
  graduation_year: z.number(),
  questions_and_answers: z.array(
    z.object({
      channel_id: z.number(),
      channel_title: z.string(),
      questions: z.array(
        z.object({
          question: z.string(),
          answers: z.array(z.string()),
        }),
      ),
      questions_textual: z.array(
        z.object({
          question: z.string(),
          answers: z.array(z.string()),
        }),
      ),
    }),
  ),
})

export type Student = z.infer<typeof previewStudentSchema>

const schema = z.object({
  data: z.object({
    students: z.record(z.array(previewStudentSchema)),
    priority_student_count: z.number(),
    total_student_count: z.number(),
  }),
})

/**
 * Search schema used to parse the URL query string
 */
export const searchOptionsSchema = z.object({
  statuses: z.array(z.string()).default([]),
  universities: z.array(z.string()).default([]),
  search_query: z.string().optional(),
})

export type StudentSearchOptions = z.infer<typeof searchOptionsSchema>

export function useFetchStudents(
  filters: FilterSelections,
  priorityFilters: FilterSelections,
) {
  return useQueryAPI({
    apiEndPoint: "/students/filter/preview",
    data: {
      campaign_filter_selection: filters,
      priority_filter_selection: priorityFilters,
    },
    method: "POST",
    params: {
      max_students_per_channel: 5,
    },
    schema,
  })
}

const byCampaignSchema = z.object({
  data: z.object({
    students: z.array(campaignStudentSchema),
    total: z.number(),
  }),
})

type FetchStudentParams = PaginationParams & {
  campaign_id: string
  searchOptions: StudentSearchOptions
  sortOptions: SortOptions
}

export function useFetchStudentsByCampaign({
  campaign_id,
  page,
  pageSize,
  searchOptions,
  sortOptions,
}: FetchStudentParams) {
  const { search_query, statuses, universities } = searchOptions

  const params = {
    campaign_id,
    page,
    page_size: pageSize,
    ...(search_query && { search_query }),
    ...(sortOptions && {
      sort_key: sortOptions?.id,
      sort_direction: sortOptions?.direction === "asc" ? "1" : "-1",
    }),
  }

  return useQueryAPI({
    apiEndPoint: `/students/search`,
    data: { statuses, universities },
    method: "POST",
    params,
    schema: byCampaignSchema,
  })
}

const exportStudentsSchema = z.object({
  data: z.object({
    csv_download_url: z.string().url(),
  }),
})

/**
Get a URL to download a CSV file containing the students in a campaign.
Same end-point as the search, but without pagination
 */
export function useExportCampaignStudentsToCSV({
  campaign_id,
  searchOptions,
  sortOptions,
}: Omit<FetchStudentParams, "page" | "pageSize">) {
  const { search_query, statuses, universities } = searchOptions
  const language = resolveLanguage()

  const params = {
    campaign_id,
    ...(search_query && { search_query }),
    ...(sortOptions && {
      sort_key: sortOptions?.id,
      sort_direction: sortOptions?.direction === "asc" ? "1" : "-1",
    }),
    language,
  }

  return useQueryAPI({
    apiEndPoint: `/students/search/export`,
    data: { statuses, universities },
    method: "POST",
    params,
    schema: exportStudentsSchema,
    options: {
      gcTime: 0, // don't use cached data, we only want fresh data
    },
  })
}

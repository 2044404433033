/* eslint-disable react-refresh/only-export-components */
/**
 * Page View tracking with PostHog
 * https://posthog.com/tutorials/single-page-app-pageviews
 */
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import postHog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

import { config } from "@/config"

const apiKey = config.postHogKey
const debugMode = false

export const userEventsMap = {
  "metrics/show": "agent_metrics_panel_opened",
  "metrics/hide": "agent_metrics_panel_closed",
  offline: "agent_switched_offline",
  online: "agent_switched_online",
  resume_work: "agent_resumed_work",
} as const

type UserEventMap = typeof userEventsMap
type UserEvent = UserEventMap[keyof UserEventMap]

if (apiKey) {
  postHog.init(apiKey, {
    // Available options: https://posthog.com/docs/libraries/js#config
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false, // because page views are captured manually
    disable_external_dependency_loading: false,
    debug: debugMode,
    verbose: !debugMode,
    disable_session_recording: false,
    disable_scroll_properties: false,
    disable_web_experiments: true,
    enable_heatmaps: false,
    session_recording: {
      // Mask everything except items marked as safe with `data-unmask = "true"`
      maskAllInputs: true,
      maskTextSelector: "*",
      maskTextFn: (text, element) => {
        if (element?.dataset["unmask"] === "true") {
          return text
        }

        return "*".repeat(text.trim().length)
      },
    },
  })
} else {
  // eslint-disable-next-line no-console
  console.log("Analytics disabled")
}

export function PHProvider({ children }: React.PropsWithChildren) {
  return <PostHogProvider client={postHog}>{children}</PostHogProvider>
}

export function PageView() {
  const location = useLocation()

  useEffect(() => {
    if (postHog) {
      postHog.capture("$pageview", {
        $current_url: window.location.href,
      })
    }
  }, [location])

  return null
}

/**
 * Our own abstraction built on top of PostHog, to be able to switch to another provider if needed
 **/
export function useAnalytics() {
  const trackEvent = (
    event: UserEvent,
    properties?: Record<string, unknown>,
  ) => {
    postHog.capture(event, properties)
  }

  const identifyPostHogUser = (email: string) => postHog.identify(email)
  // Will prevent tracking of the user by email address after logout
  // and revert to using an anonymous user
  const resetPostHogUser = () => postHog.reset(true)

  return {
    trackEvent,
    identifyPostHogUser,
    resetPostHogUser,
  }
}

export const formValidationErrorMap = {
  scheduled_call_date_too_small: "scheduledCallDateTimeTooSmall",
  scheduled_call_date_too_big: "scheduledCallDateTimeTooBig",
  other_validation_error: "otherValidationError",
} as const

export type FormValidationErrorMap = typeof formValidationErrorMap

export type FormValidationError =
  FormValidationErrorMap[keyof FormValidationErrorMap]

import { FormEventHandler, useState } from "react"
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useMount } from "react-use"

import { routesConfig } from "@/config/routes"
import { isNullish } from "@/helpers/typeguards"
import { useAuthHook } from "@/hooks/auth"
import { useAuthWithAmplify } from "@/hooks/authWithAmplify"
import { useLogger } from "@/hooks/useLogger"
import { agentStorageService } from "@/services/localStorageService"

import * as value from "./value"

type InitMode = {
  current: "init"
}
type LoadingMode = { current: "loading" }
type SuccessMode = { current: "success" }
type ErrorMode = {
  current: "error"
  state: {
    errorMessage: string
  }
}
type Mode = ErrorMode | InitMode | LoadingMode | SuccessMode

interface ResetPasswordConfirmHookType {
  confirmPasswordInputType: "password" | "text"
  form: UseFormReturn<value.Encoder.EncoderType>
  isDisabled: boolean
  isLoading: boolean
  isMatchingPassword: (
    value: string,
    formValues: value.Encoder.EncoderType,
  ) => boolean
  mode: Mode
  onChangeConfirmPasswordInputType: VoidFunction
  onChangePasswordInputType: VoidFunction
  onSubmit: FormEventHandler<HTMLFormElement>
  passwordInputType: "password" | "text"
}

const knownErrors = [
  "NotAuthorizedException",
  "ExpiredCodeException",
  "CodeMismatchException",
  "UserNotFoundException",
]

const useResetPasswordConfirmHook = (): ResetPasswordConfirmHookType => {
  const [mode, setMode] = useState<Mode>({ current: "init" })
  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password")
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState<
    "password" | "text"
  >("password")

  const { verificationCode } = useParams()
  const { changePassword } = useAuthHook()
  const { confirmPasswordForFirstSignIn } = useAuthWithAmplify()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const log = useLogger()

  const isEnabled = mode.current === "init"

  const form = useForm<value.Encoder.EncoderType>({
    defaultValues: value.Encoder.defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
  })

  const { clearErrors, handleSubmit } = form

  const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
    try {
      setMode(() => ({ current: "loading" }))

      if (!verificationCode) {
        throw new Error("Missing verification code to reset password")
      }

      if (verificationCode === "first-sign-in") {
        await confirmPasswordForFirstSignIn(data.password)
        navigate(routesConfig.AUTOMATIC_CALL_PANEL)

        return
      }

      const validEmail = await value.Decoder.emailSchema.parseAsync(
        agentStorageService.getResetPasswordEmail(),
      )

      const { password: newPassword } = await value.Decoder.schema.parseAsync(
        data,
      )

      await changePassword({ verificationCode, newPassword, email: validEmail })

      // Remove the saved email from local storage
      agentStorageService.removeResetPasswordEmail()
      navigate("/login")
    } catch (err) {
      if (err instanceof Error) {
        const isKnownError = knownErrors.includes(err.name)

        // Set a generic error message to not enable attackers to know the error
        const errorMessage = isKnownError
          ? t("errors.resetPassword.getNewCode")
          : t("errors.resetPassword.unknownError")

        setMode(() => ({
          current: "error",
          state: { errorMessage },
        }))

        if (!isKnownError) {
          log.error(err)
        }
      } else {
        setMode(() => ({
          current: "error",
          state: {
            errorMessage: t("errors.resetPassword.unknownError"),
            errorType: "recoverable",
          },
        }))

        log.error(err)
      }

      // Remove the saved email from local storage
      agentStorageService.removeResetPasswordEmail()
    }
  }

  const onChangePasswordInputType = () => {
    setPasswordInputType((prev) => (prev === "password" ? "text" : "password"))
  }

  const onChangeConfirmPasswordInputType = () => {
    setConfirmPasswordInputType((prev) =>
      prev === "password" ? "text" : "password",
    )
  }

  // Redirect when missing required data to perform the password reset
  useMount(() => {
    if (verificationCode && verificationCode === "first-sign-in") {
      return
    }

    if (!isNullish(verificationCode)) {
      const validEmailResponse = value.Decoder.emailSchema.safeParse(
        agentStorageService.getResetPasswordEmail(),
      )

      if (!validEmailResponse.success) {
        agentStorageService.removeResetPasswordEmail()
        navigate("/login")
      } else if (isNullish(verificationCode)) {
        navigate("/login")
      }
    }
  })

  const isMatchingPassword = (
    value: string,
    formValues: value.Encoder.EncoderType,
  ) => {
    const isMatchingPassword = formValues.password === value

    if (isMatchingPassword) {
      clearErrors(["confirmPassword", "password"])
    }

    return isMatchingPassword
  }

  return {
    form,
    onSubmit: handleSubmit(onSubmit),
    onChangePasswordInputType,
    onChangeConfirmPasswordInputType,
    passwordInputType,
    confirmPasswordInputType,
    mode,
    isMatchingPassword,
    isDisabled: !isEnabled,
    isLoading: mode.current === "loading",
  }
}

export { type Mode, useResetPasswordConfirmHook }

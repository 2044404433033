import { useTranslation } from "react-i18next"
import { SlidersHorizontalIcon } from "lucide-react"

import { AlertLoadingError } from "@/components/ui/alert"

import { Student, useFetchStudents } from "../api/students"
import { FilterSelections } from "../campaigns-types"
import { LoadingStudentTable, StudentTable } from "./students-table"

type Props = {
  filters: FilterSelections
  priorityFilters: FilterSelections
}

export function PreviewPanel({ filters, priorityFilters }: Props) {
  const showEmptyState = Object.keys(filters).length === 0

  return showEmptyState ? (
    <EmptyState />
  ) : (
    <PreviewPanelWithData filters={filters} priorityFilters={priorityFilters} />
  )
}

export function PreviewPanelWithData({ filters, priorityFilters }: Props) {
  const { data, error, isLoading } = useFetchStudents(filters, priorityFilters)

  if (error) {
    return <AlertLoadingError error={error} title="Unable to load students" />
  }

  if (isLoading) {
    return <LoadingViewStudentsPreview />
  }

  if (!data) return null
  const { students, total_student_count: count } = data.data

  return <ViewStudentsPreview studentsByChannel={students} count={count} />
}

export function ViewStudentsPreview({
  count,
  studentsByChannel,
}: {
  count: number
  studentsByChannel: Record<string, Student[]>
}) {
  const { t } = useTranslation()

  const title =
    count > 0
      ? t("campaigns.preview.table.title", { count })
      : t("campaigns.preview.table.noResults")

  return (
    <ViewLayout title={title}>
      <StudentTable studentsByChannel={studentsByChannel} />
    </ViewLayout>
  )
}

export function LoadingViewStudentsPreview() {
  const { t } = useTranslation()

  return (
    <ViewLayout title={t("global.button.loading")}>
      <LoadingStudentTable />
    </ViewLayout>
  )
}

function ViewLayout({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-xl">{title}</h3>
      <div>{children}</div>
    </div>
  )
}

function EmptyState() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full items-center justify-center rounded border">
      <div className="flex flex-col items-center gap-4 text-center text-xl text-primary-300">
        <SlidersHorizontalIcon size={48} />
        {t("campaigns.preview.emptyState")}
      </div>
    </div>
  )
}

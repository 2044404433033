import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useAtomValue } from "jotai"

import { ShowCallReportErrorToast } from "@/components/ACWForm/report-error-card"
import { ShowErrorToast } from "@/components/CallPanel/call-error-card"
import GoOfflineWithContactModal from "@/components/modals/GoOfflineWithContactModal"
import { agentStatusNameAtom } from "@/helpers/atoms"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useACWButtonsHook } from "./ACWButtons/hook"
import { useCallPanelActions } from "./callpanel-actions"
import { CallPanelButtons } from "./callpanel-buttons"
import { CallPanelCard } from "./callpanel-card"
import { CallPanelStatusIcon } from "./callpanel-status-icon"
import { useCallPanelHook } from "./hook"
import { IncomingCallNotificationCard } from "./incoming-call-card"

interface Props {
  acwButtonState: ReturnType<typeof useACWButtonsHook>
  callPanelState: ReturnType<typeof useCallPanelHook>
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  setHistoryItem: Dispatch<
    SetStateAction<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>
  >
}

const CallPanel: FunctionComponent<Props> = ({
  acwButtonState,
  callPanelState,
  currentCase,
  historyItem,
}) => {
  const {
    campaignName,
    displayMode,
    isMissedTask,
    modalMode,
    phoneNumber,
    setHasCalled,
    shouldDisplayCounter,
    switchCallReportMode,
    timer,
  } = callPanelState
  const { t } = useTranslation()

  const agentStatusName = useAtomValue(agentStatusNameAtom)

  const isConnectingInboundCall =
    displayMode.current === "connecting" && displayMode.state.isInboundCall

  const actions = useCallPanelActions({
    displayMode,
    phoneNumber,
    setHasCalled,
    switchCallReportMode,
  })

  return (
    <div
      data-testid="call-panel"
      className="col-span-full row-start-2 flex flex-col lg:col-start-2"
    >
      <ShowErrorToast className="mb-2" />
      <ShowCallReportErrorToast className="mb-2" />
      {/* Show a notification card on incoming Inbound calls */}
      <IncomingCallNotificationCard isConnecting={isConnectingInboundCall} />
      {/* Display modals when agent tries to go offline and a contact is connected */}
      {modalMode?.mode === "goOfflineWithContact" && (
        <GoOfflineWithContactModal currentCase={currentCase} />
      )}
      <CallPanelCard
        campaignName={campaignName}
        phoneNumber={phoneNumber}
        timer={shouldDisplayCounter ? timer : null}
        status={
          !isMissedTask &&
          agentStatusName !== "FailedConnectAgent" &&
          !actions.isSkippedCall ? (
            <CallPanelStatusIcon displayMode={displayMode} />
          ) : null
        }
        buttons={
          actions.isSkippedCall ? null : (
            <CallPanelButtons
              displayMode={displayMode}
              actions={actions}
              acwButtonState={{ ...acwButtonState, isDisabled: !historyItem }}
            />
          )
        }
        error={isMissedTask ? t("callPanel.status.missedTask") : null}
      />
    </div>
  )
}

export default CallPanel

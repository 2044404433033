/* eslint-disable react-refresh/only-export-components */
import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import { Status, StatusUsageContext } from "@repo/core/models"

import { resolveLanguage } from "@/helpers/i18n"
import { isNull } from "@/helpers/typeguards"

import { CallStatusDef, useFetchAllCallStatuses } from "./call-statuses"

type StatusesContextType = {
  getStatusDef: (status: Status) => CallStatusDef | undefined
  getStatusesByUseCase: (useCase: StatusUsageContext | null) => CallStatusDef[]
  isLoading: boolean
  statuses: CallStatusDef[]
}

const StatusesContext = createContext<StatusesContextType | null>(null)

export function CallStatusesProvider({ children }: PropsWithChildren) {
  const { data, isLoading } = useFetchAllCallStatuses()

  return (
    <CallStatusesDataProvider data={data} isLoading={isLoading}>
      {children}
    </CallStatusesDataProvider>
  )
}

/** Provider to be fed:
 * - with data from the API when running the app
 * - or mock data when testing and running Storybook
 **/
export function CallStatusesDataProvider({
  children,
  data,
  isLoading,
}: PropsWithChildren<{
  data: ReturnType<typeof useFetchAllCallStatuses>["data"]
  isLoading: boolean
}>) {
  const allStatuses = useMemo(() => data?.data || [], [data?.data])

  function getStatusDef(id: Status) {
    return allStatuses.find((status) => status.id === id)
  }

  const statusesByUseCaseMap = useMemo(() => {
    const map = new Map<StatusUsageContext, CallStatusDef[]>()
    allStatuses.forEach((status) => {
      status.usageContext.forEach((context) => {
        if (!map.has(context)) map.set(context, [])
        map.get(context)?.push(status)
      })
    })

    return map
  }, [allStatuses])

  function getStatusesByUseCase(useCase: StatusUsageContext | null) {
    return isNull(useCase) ? [] : statusesByUseCaseMap.get(useCase) || []
  }

  const state = {
    getStatusesByUseCase,
    statuses: allStatuses,
    isLoading,
    getStatusDef,
  }

  return (
    <StatusesContext.Provider value={state}>
      {children}
    </StatusesContext.Provider>
  )
}

export function useCallStatuses() {
  const state = useContext(StatusesContext)
  if (!state) {
    throw new Error("Must be used within a CallStatusesProvider")
  }

  return state
}

export function useCallStatusLabel() {
  const state = useCallStatuses()
  const language = resolveLanguage()

  function getLabel(status: Status) {
    if (state.isLoading) return "..."
    const statusDef = state.getStatusDef(status)

    return statusDef ? statusDef[language] : status + ` [Not found]`
  }

  return getLabel
}

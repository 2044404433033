/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { useTranslation } from "react-i18next"
import { type VariantProps } from "class-variance-authority"
import { ChevronDownIcon, X } from "lucide-react"

import { Button, ButtonProps } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/helpers/classNames"

import { Checkbox } from "./checkbox"
import { comboboxVariants } from "./combobox"

export type ComboboxOptions<T extends number | string> = {
  label: string
  value: T
}

type Props<T extends number | string> = VariantProps<
  typeof comboboxVariants
> & {
  allowReset?: boolean
  buttonProps?: ButtonProps
  onChange: (values: T[]) => void
  options: ComboboxOptions<T>[]
  placeholder?: string
  popoverClassName?: string
  renderSelectedOptions?: (options: ComboboxOptions<T>[]) => React.ReactNode
  values: T[]
}

/**
 * A component to select multiple values from a list of options, displaying checkboxes in a popover
 * with a search input to filter the options
 * The rendering of the selected options can be customized using the `renderSelectedOptions` prop
 */
export function MultiCombobox<T extends number | string>({
  allowReset = false,
  buttonProps,
  onChange,
  options,
  placeholder,
  popoverClassName,
  renderSelectedOptions,
  size,
  values,
}: Props<T>) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const isNumber = typeof options![0]?.value === "number"

  const selectedOptions = options!.filter((option) =>
    values.includes(option.value),
  )

  function add(value: T) {
    onChange([...values, value])
  }
  function remove(value: T) {
    onChange(values.filter((v) => v !== value))
  }

  // Command `onSelect` callback only handles string values
  function handleSelect(selectedValue: string) {
    const valuesAsString = (isNumber ? values.map(String) : values) as string[]
    const parsedValue = (isNumber ? Number(selectedValue) : selectedValue) as T
    if (valuesAsString.includes(selectedValue)) {
      remove(parsedValue)
    } else {
      add(parsedValue)
    }
  }

  function handleReset(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    onChange([])
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(comboboxVariants({ size }), "justify-between")}
          {...buttonProps}
        >
          {renderSelectedOptions ? (
            renderSelectedOptions(selectedOptions)
          ) : (
            <DisplaySelectedOptions
              options={selectedOptions}
              placeholder={placeholder}
            />
          )}
          {allowReset && values.length > 0 ? (
            <div role="button" tabIndex={0} onClick={handleReset}>
              <X className="ml-2 size-4 shrink-0 opacity-50" />
            </div>
          ) : (
            <ChevronDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(comboboxVariants({ size }), "p-0", popoverClassName)}
      >
        <Command>
          <CommandInput placeholder={t("global.search.action")} />
          <CommandList>
            <CommandEmpty>{t("global.search.noItemsFound")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value.toString()}
                  keywords={[option.label]}
                  onSelect={handleSelect}
                >
                  <Checkbox
                    checked={values.includes(option.value)}
                    className="mr-2"
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

/**
 * Default component to render the selected options in the MultiCombobox
 */
function DisplaySelectedOptions<T extends number | string>({
  options,
  placeholder = "—",
}: {
  options: ComboboxOptions<T>[]
  placeholder?: string
}) {
  const { t } = useTranslation()

  if (!options.length) {
    return <div className="text-muted-foreground">{placeholder}</div>
  }
  if (options.length === 1) {
    return <div className="truncate">{options[0].label}</div>
  }

  return (
    <div className="truncate">
      {t("global.search.selectedValues", { count: options.length })}
    </div>
  )
}

import { addDays } from "date-fns"
import { z } from "zod"

import {
  caseIdSchema,
  datetimeSchema,
  idSchema,
  optionalDateTimeSchema,
} from "@/helpers/zodSchemas"

namespace Encoder {
  export const defaultValues = (rawCallReport: unknown) => {
    try {
      return Decoder.schema.parse(rawCallReport)
    } catch (error) {
      return { status: "", reason: "", scheduledCallDate: null, memo: "" }
    }
  }
}

namespace Decoder {
  export const schema = z
    .object({
      status: z.string(),
      reason: z.string(),
      scheduledCallDate: datetimeSchema.nullable(),
      memo: z.string(),
      updated_at: optionalDateTimeSchema,
    })
    .superRefine(({ scheduledCallDate }, ctx) => {
      const minDateMs = Date.now()
      const maxDateMs = minDateMs + 7 * 24 * 60 * 60 * 1000

      if (scheduledCallDate && scheduledCallDate < new Date()) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: minDateMs,
          type: "date",
          inclusive: true,
          message: "Scheduled call date is too far in the past",
          path: ["scheduledCallDate"],
        })
      }

      if (scheduledCallDate && scheduledCallDate > addDays(new Date(), 6)) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: maxDateMs,
          type: "date",
          inclusive: true,
          message: "Scheduled call date is too far in the future",
          path: ["scheduledCallDate"],
        })
      }
    })

  export type DecoderType = z.infer<typeof schema>

  export const saveReportSchema = z.object({
    data: z.object({
      case_id: caseIdSchema,
      is_campaign_no_call: z.boolean(),
      is_global_no_call: z.boolean().nullable(),
      new_contact_id: idSchema.nullable(),
    }),
    reqId: idSchema,
    success: z.string(),
  })

  export type SaveReportResult = z.infer<typeof saveReportSchema>
}

export { Decoder, Encoder }

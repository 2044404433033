import { fetchAuthSession } from "@aws-amplify/auth"
import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"

import { config as calliopeConfig } from "@/config/index"

const getAmplifyToken = async (): Promise<string> => {
  const session = await fetchAuthSession()
  const accessToken = session?.tokens?.accessToken?.toString()

  if (!accessToken) {
    throw new Error("No valid session found")
  }

  return accessToken
}

const getAxiosClient = async (
  config: CreateAxiosDefaults = {},
): Promise<AxiosInstance> => {
  const baseApiUrl = `https://${calliopeConfig.apiEndpoint}`
  const accessToken = await getAmplifyToken()

  const axiosClient = axios.create({
    baseURL: baseApiUrl,
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    ...config,
  })

  return axiosClient
}

export { getAxiosClient }

import { FieldError, FieldErrors } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  hasProperty,
  isDefined,
  isNullish,
  isString,
} from "@/helpers/typeguards"

const getError = (
  errors: FieldErrors | undefined,
  path: string,
): FieldError | undefined => {
  const isFieldErrorLike = (value: unknown): value is FieldError =>
    hasProperty("type", isString)(value) &&
    hasProperty("message", isString)(value)

  const splitPath = path.split(".")

  let found: FieldError | FieldErrors | undefined = errors

  for (const pathSegment of splitPath) {
    if (isNullish(found) || isFieldErrorLike(found)) {
      break
    } else {
      found = found[pathSegment]
    }
  }

  return isFieldErrorLike(found) ? found : undefined
}

const useErrorMessage = (
  name: string,
  errors?: FieldErrors,
  label?: string,
) => {
  const { getErrorMessage } = useFieldErrorMessage()

  const errorLabel = label || name

  if (isDefined(errors)) {
    const error = getError(errors, name)

    return getErrorMessage(error?.type, errorLabel) || undefined
  }

  return undefined
}

export function useFieldErrorMessage() {
  const { t } = useTranslation()

  function getErrorMessage(
    errorType: FieldError["type"] | undefined,
    errorLabel: string,
  ) {
    if (!errorType) return null // nothing to display when there is no error!

    switch (errorType) {
      case "required":
        return t("errors.form.required", { fieldName: errorLabel })
      case "wrongDate":
        return t("errors.form.wrongDate")
      case "unknown":
        return t("errors.form.unknown", { fieldName: errorLabel })
      case "isValidPassword":
        return t("errors.login.passwordFormat", { fieldName: errorLabel })
      case "validEmailAddress":
        return t("errors.login.emailFormat", { fieldName: errorLabel })
      case "validStartDateTime":
        return t("errors.form.validStartDateTime", { fieldName: errorLabel })
      case "isMatchingPassword":
        return t("errors.resetPassword.mismatchingPassword", {
          fieldName: errorLabel,
        })
      case "scheduledCallDateTimeWithinBounds":
        return t("errors.form.scheduledCallDateTimeWithinBounds", {
          fieldName: errorLabel,
        })
      default:
        return null
    }
  }

  return { getErrorMessage }
}

export { useErrorMessage }

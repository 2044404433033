import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { statusUsageMap } from "@repo/core/models"
import i18n from "i18next"
import { AlertCircle, ChevronLeftIcon, InfoIcon } from "lucide-react"

import FileUploader from "@/components/FileUploader"
import Icon from "@/components/Icon"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { resolveLanguage } from "@/helpers/i18n"
import { isNullish } from "@/helpers/typeguards"
import { useCallStatuses } from "@/hooks/call-statuses-context"

import { useImportCSV } from "./import-csv"
import { InvalidData } from "./import-csv-schemas"

type Props = {
  campaign_id: string
  close: (result: boolean | null) => void
}

export function DialogImportCSV({ campaign_id, close }: Props) {
  const { t } = useTranslation()
  const { getStatusesByUseCase } = useCallStatuses()

  const { file, onFileChange, reset, results, step, upload } =
    useImportCSV(campaign_id)

  const isProcessing = step === "processing"

  if (step === "success") {
    return (
      <SuccessState
        close={close}
        onGoBack={reset}
        updatedRowCount={results?.data.updated_row_count || 0}
        invalid_data={results?.data.invalid_data || []}
      />
    )
  }

  if (step === "error") {
    return <ErrorState onGoBack={reset} />
  }

  const language = resolveLanguage()

  const allowedStatuses = getStatusesByUseCase(
    statusUsageMap.campaignDetailsCsvUpload,
  ).map((statusDef) => statusDef[language])

  return (
    <DialogWrapper isProcessing={isProcessing}>
      <DialogBody>
        <div className="flex flex-col gap-y-4">
          <h2 className="font-bold">{t("entries.upload.panelTitle")}</h2>

          <ol className="flex list-inside list-decimal flex-col gap-4 font-normal text-neutral-700">
            <li>
              {t("entries.upload.instruction1")}
              <div className="pl-4">
                <a
                  href="/templates/entry_template.csv"
                  download
                  className="flex items-center gap-x-2 font-bold text-primary-400"
                >
                  template.csv <Icon name="download" />
                </a>
              </div>
            </li>

            <li className="">
              {t("entries.upload.instruction2")}
              <ul className="list-inside list-disc pl-4">
                <li>{t("entries.upload.stepText1")}</li>
                <li>
                  {t("entries.upload.stepText2")}
                  <ul className="ml-8 list-inside list-disc">
                    {allowedStatuses.map((status) => (
                      <li key={status}>{status}</li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>

            <li className="justify-start">
              {t("entries.upload.instruction3")}
              <FileUploader
                accept=".csv"
                disabled={isProcessing}
                className="mt-2"
                fileName={file?.name}
                onUpload={onFileChange}
                placeholder={t("entries.upload.noFilePlaceholder")}
              />
            </li>
          </ol>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => close(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button isDisabled={!file} isLoading={isProcessing} onClick={upload}>
          {t("global.button.upload")}
        </Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

/** Show any combination of error / updated rows results */
function SuccessState({
  close,
  invalid_data,
  onGoBack,
  updatedRowCount = 0,
}: {
  close: (result: boolean | null) => void
  invalid_data: InvalidData[]
  onGoBack: () => void
  updatedRowCount?: number
}) {
  const { t } = useTranslation()
  const errorCount = invalid_data.length

  // Happy path, no errors!
  if (updatedRowCount > 0 && errorCount === 0) {
    return (
      <DialogWrapper>
        <DialogBodySuccess>
          <p className="mb-4 font-bold">
            {t("entries.upload.modal.successTitle")}
          </p>
          {t("entries.upload.modal.successDescription", {
            count: updatedRowCount,
          })}
        </DialogBodySuccess>
        <DialogFooter>
          <Button onClick={() => close(false)} variant="outline">
            {t("global.button.close")}
          </Button>
        </DialogFooter>
      </DialogWrapper>
    )
  }

  // More probable, show two `Alert` blocks: one for the summary and one for the errors
  return (
    <DialogWrapper>
      <DialogBody>
        <div className="flex flex-col gap-3">
          {updatedRowCount > 0 && (
            <Alert>
              <InfoIcon className="size-4" />
              <AlertDescription>
                {t("entries.upload.modal.successDescription", {
                  count: updatedRowCount,
                })}
              </AlertDescription>
            </Alert>
          )}

          {errorCount > 0 && (
            <Alert variant="destructive">
              <AlertCircle className="size-4" />
              <AlertTitle className="font-bold">
                {t("entries.upload.modal.errorTitle", { count: errorCount })}
              </AlertTitle>
              <AlertDescription>
                <div className="h-[300px] overflow-y-auto">
                  <ul className="ml-5 list-disc">
                    {invalid_data.map((item: InvalidData) => (
                      <li key={`${item.row_index},${item.column_index}`}>
                        {formatErrorMessage(item)}
                      </li>
                    ))}
                  </ul>
                </div>
              </AlertDescription>
            </Alert>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onGoBack} variant="outline">
          <ChevronLeftIcon />
          {t("global.button.goBack")}
        </Button>
        <Button onClick={() => close(false)} variant="outline">
          {t("global.button.close")}
        </Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

/** Only to catch unexpected errors from the API (no need to translate the message) */
function ErrorState({ onGoBack }: { onGoBack: () => void }) {
  const { t } = useTranslation()

  return (
    <DialogWrapper>
      <DialogBodyError>
        Unexpected error. Unable to upload the file.
      </DialogBodyError>
      <DialogFooter>
        <Button onClick={onGoBack}>{t("global.button.goBack")}</Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

function DialogWrapper({
  children,
  isProcessing,
}: PropsWithChildren<{ isProcessing?: boolean }>) {
  const { t } = useTranslation()

  return (
    <DialogContent className="max-w-2xl" isPending={isProcessing}>
      <DialogHeader>
        <DialogTitle>{t("entries.upload.title")}</DialogTitle>
      </DialogHeader>
      {children}
    </DialogContent>
  )
}

function formatErrorMessage(item: InvalidData) {
  const { column_index: column, message, row_index: row } = item
  if (isNullish(row)) return message
  if (isNullish(column))
    return i18n.t("campaigns.details.importCSV.errorRow", {
      message,
      row,
    })

  return i18n.t("campaigns.details.importCSV.errorRowColumn", {
    message,
    row,
    column,
  })
}

import { Status, statusMap } from "@repo/core/models"
import { z } from "zod"

import * as callViewValue from "@/components/CallView/value"
import {
  caseIdSchema,
  connectUserIdSchema,
  datetimeSchema,
  idSchema,
} from "@/helpers/zodSchemas"

namespace AgentInfo {
  export const agentInfoSchema = z.object({
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string().nullable(),
  })

  export type AgentInfoType = z.infer<typeof agentInfoSchema>

  export type EncoderInputType = {
    email: string | null
    firstName: string | null
    lastName: string | null
  }

  export const encode = ({
    email,
    firstName,
    lastName,
  }: EncoderInputType): AgentInfoType => {
    return {
      first_name: firstName ?? null,
      last_name: lastName ?? null,
      email: email ?? null,
    }
  }
}

namespace CallContact {
  const contactTypeSchema = z.enum(["inbound", "outbound"])

  export type ContactType = z.infer<typeof contactTypeSchema>

  const callContactIdSchema = z.object({
    connect_user_id: connectUserIdSchema,
    contact_id: idSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
    type: contactTypeSchema,
  })

  export const callContactWithAgentInfoSchema = callContactIdSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type CallContactType = z.infer<typeof callContactWithAgentInfoSchema>

  export const encode = (
    contactType: ContactType,
    agentInfo: AgentInfo.EncoderInputType,
  ): CallContactType => {
    return {
      connect_user_id: "",
      contact_id: "",
      created_at: new Date(),
      group_id: "",
      type: contactType,
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace Comment {
  const commentSchema = z.object({
    comment: z.string(),
    connect_user_id: connectUserIdSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
  })

  export const commentWithAgentInfoSchema = commentSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type CommentType = z.infer<typeof commentWithAgentInfoSchema>

  export const encode = (
    comment: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
  ): CommentType => {
    return {
      comment: comment ?? "",
      connect_user_id: "",
      created_at: new Date(),
      group_id: "",
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace StatusEvent {
  const statusEventSchema = z.object({
    connect_user_id: connectUserIdSchema,
    created_at: datetimeSchema,
    event_name: z.nativeEnum(statusMap),
    group_id: idSchema,
  })

  export const statusEventWithAgentInfoSchema = statusEventSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type StatusEventType = z.infer<typeof statusEventWithAgentInfoSchema>

  export const encode = (
    eventName: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
    createdAt: Date | undefined,
  ): StatusEventType => {
    if (!eventName) {
      throw new Error("Event name is required")
    }

    return {
      connect_user_id: "",
      created_at: createdAt ?? new Date(),
      event_name: eventName as Status,
      group_id: "",
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace TaskContact {
  const taskContactIdSchema = z.object({
    connect_user_id: connectUserIdSchema,
    contact_id: idSchema,
    created_at: datetimeSchema,
    group_id: idSchema,
    reason: z.string().nullish(),
    scheduled_at: datetimeSchema.nullish(),
  })

  export const taskContactWithAgentInfoSchema = taskContactIdSchema.merge(
    AgentInfo.agentInfoSchema,
  )

  export type TaskContactType = z.infer<typeof taskContactWithAgentInfoSchema>

  export const encode = (
    reason: string | null | undefined,
    agentInfo: AgentInfo.EncoderInputType,
  ): TaskContactType => {
    if (!reason) {
      throw new Error("Reason is required")
    }

    return {
      connect_user_id: "",
      contact_id: "",
      created_at: new Date(),
      group_id: "",
      reason,
      ...AgentInfo.encode(agentInfo),
    }
  }
}

namespace ActivityFeedEntry {
  export const activityFeedSchema = z.object({
    call_contact: CallContact.callContactWithAgentInfoSchema.nullish(),
    comment: Comment.commentWithAgentInfoSchema.nullish(),
    status_event: StatusEvent.statusEventWithAgentInfoSchema.nullish(),
    task_contact: TaskContact.taskContactWithAgentInfoSchema.nullish(),
    campaign_id: z.string(),
  })

  export type ActivityFeedEntryType = z.infer<typeof activityFeedSchema>

  export const encode = (
    data: callViewValue.Decoder.DecoderType,
    agentInfo: AgentInfo.EncoderInputType,
    campaignId: string,
    contactType: CallContact.ContactType | undefined = "outbound",
  ): ActivityFeedEntryType => {
    return {
      call_contact: CallContact.encode(contactType, agentInfo),
      comment: Comment.encode(data.memo, agentInfo),
      status_event: StatusEvent.encode(
        data.reason || data.status,
        agentInfo,
        data.updated_at,
      ),
      task_contact: TaskContact.encode(data.reason || data.status, agentInfo),
      campaign_id: campaignId,
    }
  }
}

namespace Decoder {
  export const hiddenActivityReason: readonly string[] = [
    "scheduled",
    "renewed",
    "recovery",
  ] as const

  const selectedTextualQuestionSchema = z.object({
    answer: z.string().nullable(),
    question: z.string().nullable(),
  })

  export type SelectedTextualQuestion = z.infer<
    typeof selectedTextualQuestionSchema
  >

  const selectedTextualQuestionsSchema = selectedTextualQuestionSchema.array()

  const selectedQuestionSchema = z.object({
    answers: z.string().array().nullable(),
    question: z.string().nullable(),
  })

  export type SelectedQuestion = z.infer<typeof selectedQuestionSchema>

  const selectedQuestionsSchema = selectedQuestionSchema.array()

  export const caseSchema = z.object({
    _id: caseIdSchema,
    call_contact_ids: CallContact.callContactWithAgentInfoSchema.array(),
    campaign_id: z.string(),
    comments: Comment.commentWithAgentInfoSchema.array(),
    created_at: datetimeSchema,
    current_status: z.nativeEnum(statusMap),
    latest_repeater_status: z.nativeEnum(statusMap).nullable(),
    latest_status_action_taken: z.nativeEnum(statusMap).nullable(), //null when there are no status events with action taken in the history
    no_call: z.boolean(),
    processed_data: z
      .object({
        selected_questions: selectedQuestionsSchema,
        selected_textual_questions: selectedTextualQuestionsSchema,
      })
      .optional(),
    status_events: StatusEvent.statusEventWithAgentInfoSchema.array(),
    student_id: idSchema,
    task_contact_ids: TaskContact.taskContactWithAgentInfoSchema.array(),
    updated_at: datetimeSchema,
  })

  export const caseAndActivityFeedSchema = z.object({
    case: caseSchema,
    activity_feed: ActivityFeedEntry.activityFeedSchema.array(),
    global_activity_feed: ActivityFeedEntry.activityFeedSchema
      .array()
      .optional(), // made optional as it's not used anywhere.
  })

  export const caseInformationSchema = z.object({
    data: caseAndActivityFeedSchema,
    reqId: idSchema,
    success: z.string(),
  })

  export const caseInformationResponseSchema = z.object({
    data: caseInformationSchema,
    status: z.number(),
  })

  export type Case = z.infer<typeof caseSchema>
  export type CaseAndActivityFeeds = z.infer<typeof caseAndActivityFeedSchema>
  export type CaseInformationResponse = z.infer<typeof caseInformationSchema>

  // Moved the student profile schema here to keep it in the same place with the case schema;
  // Results in less stuff to edit in the codebase.
  export const studentProfileSchema = z.object({
    department: z.string(),
    email: z.string(),
    first_name: z.string(),
    first_name_kana: z.string(),
    gender: z.string(),
    graduation_year: z.number(),
    last_name_kana: z.string(),
    last_name: z.string(),
    legacy_id: z.number(),
    phone_number: z.string(),
    student_id: z.string(),
    university: z.string(),
  })

  export type StudentProfile = z.infer<typeof studentProfileSchema>

  export const customerProfileSchema = z.object({ data: studentProfileSchema })
}

export { ActivityFeedEntry, Decoder }

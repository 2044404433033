/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { atom, useAtom } from "jotai"
import { ZodError } from "zod"

import Icon from "@/components/Icon"
import { cn } from "@/helpers/classNames"
import { useI18n } from "@/helpers/i18n/use-translation"
import { unique } from "@/helpers/other"
import {
  type FormValidationError,
  type FormValidationErrorMap,
  formValidationErrorMap,
} from "@/models/form-validation-errors"

const formValidationErrorAtom = atom<Array<FormValidationError>>([])

export const useCallReportValidationError = () => {
  const [errors, setErrors] = useAtom(formValidationErrorAtom)

  const getValidationErrorKey = (
    error: ZodError,
  ): keyof FormValidationErrorMap => {
    const foundDateError = error.issues.find((issue) =>
      issue.path.includes("scheduledCallDate"),
    )

    if (foundDateError && foundDateError.code == "too_small") {
      return "scheduled_call_date_too_small"
    }

    if (foundDateError && foundDateError.code == "too_big") {
      return "scheduled_call_date_too_big"
    }

    return "other_validation_error"
  }

  const pushCallReportError = (error: ZodError) => {
    const validationErrorKey = getValidationErrorKey(error)
    const validationError = formValidationErrorMap[validationErrorKey]

    setErrors((prevErrors) => unique([...prevErrors, validationError]))
  }

  const onClose = (message: FormValidationError) => {
    setErrors((errors) => errors.filter((err) => err !== message))
  }

  const resetCallReportErrors = () => setErrors([])

  return {
    pushCallReportError,
    resetCallReportErrors,
    onClose,
    errors,
  }
}

const CallReportErrorCard: FunctionComponent<
  PropsWithChildren & { error: FormValidationError }
> = ({ children, error }) => {
  const { t } = useTranslation()
  const { onClose } = useCallReportValidationError()
  const title = t(`callPanel.reportErrorCard.${error}.title`)

  return (
    <div className="font-body-small-regular flex flex-col gap-y-2 rounded-sm border border-danger-200 bg-danger-100 px-4 py-3 text-neutral-600">
      <div className="flex flex-row flex-wrap items-center gap-x-2.5">
        <Icon name="alert" className="text-danger-300" />
        <div className="font-body-small-bold grow" data-unmask>
          {title}
        </div>
        <div className="cursor-pointer" onClick={() => onClose(error)}>
          <Icon name="close" className="text-danger-200" />
        </div>
      </div>
      <>{children}</>
    </div>
  )
}

const CallReportErrorDescription = ({
  error,
}: {
  error: FormValidationError
}) => {
  const { tMap } = useI18n()

  return (
    <div>
      {tMap(
        `callPanel.reportErrorCard.${error}.description`,
        (descriptionText, i) =>
          Array.isArray(descriptionText) ? (
            <ul className="list-inside list-disc" key={i} data-unmask>
              {tMap(
                `callPanel.reportErrorCard.${error}.description.${i}`,
                (descSubtext, j) => (
                  <li key={j}>{descSubtext}</li>
                ),
              )}
            </ul>
          ) : (
            <p key={i}>{descriptionText}</p>
          ),
      )}
    </div>
  )
}

export const ShowCallReportErrorToast: FunctionComponent<{
  className?: string
}> = ({ className }) => {
  const { errors } = useCallReportValidationError()

  if (!errors.length) {
    return null
  }

  return (
    <div className={cn(className, "flex flex-col gap-y-2")}>
      {errors.map((formValidationError, i) => (
        <CallReportErrorCard key={i} error={formValidationError}>
          <CallReportErrorDescription key={i} error={formValidationError} />
        </CallReportErrorCard>
      ))}
    </div>
  )
}

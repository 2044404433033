import { useTranslation } from "react-i18next"
import { FilterIcon, PlusIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

import { AddStudentsControl } from "../add-student/add-students-control"
import { ChannelsControl } from "../channels/channels-control"
import {
  GenericFilterPicker,
  LoadingFilter,
} from "../pickers/generic-filter-picker"
import { YearPicker } from "../pickers/year-picker"
import { UniversityFilterControl } from "../university/universities-filter-control"
import { useFilterState } from "./edit-filters-hooks"
import { FilterPicker } from "./filter-picker"
import { FilterControl, FilterPanelLayout } from "./filters-panel-shared"
import { useFiltersPanelState } from "./filters-state"

type Props = ReturnType<typeof useFilterState> & {
  campaign_id: string | null
}

export function FiltersPanel(props: Props) {
  const {
    areAllFiltersHidden,
    availableFiltersToAdd,
    handleAddFilter,
    handleEditChannels,
    handleEditPhoneNumbers,
    handleEditUniversities,
    handleResetFilter,
    isFilterVisible,
    onChangeFilter,
  } = useFiltersPanelState(props)
  const { t } = useTranslation()
  const { filters } = props

  return (
    <FilterPanelLayout
      isEmpty={areAllFiltersHidden}
      header={
        <>
          <FilterIcon className="size-6" />
          {t("campaigns.filters.title")}
        </>
      }
      addButton={
        <FilterPicker
          availableFilters={availableFiltersToAdd}
          onChange={handleAddFilter}
        >
          <Button
            variant="link"
            className="h-auto gap-0 p-0"
            size="sm"
            isDisabled={availableFiltersToAdd.length === 0}
          >
            <PlusIcon className="size-4" />
            {t("campaigns.filters.add.short")}
          </Button>
        </FilterPicker>
      }
      emptyState={
        <FilterPicker
          availableFilters={availableFiltersToAdd}
          onChange={handleAddFilter}
        >
          <Button variant="soft" className="w-campaign-filter">
            {t("campaigns.filters.add.long")}
          </Button>
        </FilterPicker>
      }
    >
      {isFilterVisible("channels") && (
        <FilterControl
          label={t("campaigns.filters.channels.label")}
          onReset={handleResetFilter("channels")}
        >
          <ChannelsControl
            channels={filters.channels || []}
            onEdit={handleEditChannels}
          />
        </FilterControl>
      )}
      {isFilterVisible("university") && (
        <FilterControl
          label={t("campaigns.filters.university.label")}
          onReset={handleResetFilter("university")}
        >
          <UniversityFilterControl
            filters={filters.university || []}
            onEdit={handleEditUniversities}
          />
        </FilterControl>
      )}
      {isFilterVisible("graduation_year") && (
        <FilterControl onReset={handleResetFilter("graduation_year")}>
          <YearPicker
            values={filters.graduation_year?.values || []}
            onChange={onChangeFilter("graduation_year")}
          />
        </FilterControl>
      )}
      {isFilterVisible("gender") && (
        <FilterControl onReset={handleResetFilter("gender")}>
          <GenericFilterPicker
            filterKey="gender"
            propertyName="genders"
            values={filters.gender?.values || []}
            onChange={onChangeFilter("gender")}
          />
        </FilterControl>
      )}
      {isFilterVisible("department_group") && (
        <FilterControl onReset={handleResetFilter("department_group")}>
          <GenericFilterPicker
            propertyName="department_groups"
            filterKey="department_group"
            values={filters.department_group?.values || []}
            onChange={onChangeFilter("department_group")}
          />
        </FilterControl>
      )}
      {isFilterVisible("major") && (
        <FilterControl onReset={handleResetFilter("major")}>
          <GenericFilterPicker
            propertyName="majors"
            filterKey="major"
            values={filters.major?.values || []}
            onChange={onChangeFilter("major")}
          />
        </FilterControl>
      )}
      {isFilterVisible("selected_phone_numbers") && (
        <FilterControl onReset={handleResetFilter("selected_phone_numbers")}>
          <AddStudentsControl
            values={filters.selected_phone_numbers || []}
            onEdit={handleEditPhoneNumbers}
          />
        </FilterControl>
      )}
    </FilterPanelLayout>
  )
}

export function LoadingFiltersPanel() {
  const { t } = useTranslation()

  return (
    <FilterPanelLayout header={t("campaigns.filters.title")}>
      <LoadingFilter />
    </FilterPanelLayout>
  )
}

import { Button } from "@/components/ui/button"
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { useI18n } from "@/helpers/i18n"

import { Campaign } from "../campaigns-types"
import { useMutationChangeCampaignStatus } from "./campaign-queries"
import { DialogMutation } from "./dialog-mutation"

type DialogProps = {
  campaign: Campaign
  close: (value: boolean) => void
}

export function DialogResumeCampaign({ campaign, close }: DialogProps) {
  const { t, tMap } = useI18n()

  const mutation = useMutationChangeCampaignStatus(campaign.campaign_id)

  async function handleResume(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    await mutation.mutateAsync(true)
  }

  return (
    <DialogContent isPending={mutation.isPending}>
      <form onSubmit={handleResume}>
        <DialogHeader>
          <DialogTitle>{t("campaigns.dialogs.resume.title")}</DialogTitle>
        </DialogHeader>

        <DialogMutation
          status={mutation.status}
          action={
            <Button type="submit" isLoading={mutation.isPending}>
              {t("campaigns.actions.resume")}
            </Button>
          }
          successMessage={t("campaigns.dialogs.resume.success")}
          errorMessage={t("campaigns.dialogs.error")}
          close={close}
        >
          <div className="flex flex-col gap-4">
            {tMap("campaigns.dialogs.resume.confirm", (text, index) => (
              <p key={index}>{text}</p>
            ))}
            <p>
              <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                {campaign.campaign_id}
              </code>
            </p>
          </div>
        </DialogMutation>
      </form>
    </DialogContent>
  )
}

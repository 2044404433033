import { useNavigate } from "react-router-dom"
import { useMount } from "react-use"

import { routesConfig } from "@/config/routes"
import { useAuthHook } from "@/hooks/auth"

const useForbidAuthedAgentRouteHook = (): void => {
  const { checkIsValidAuthSession } = useAuthHook()
  const navigate = useNavigate()

  useMount(() => {
    const checkIfAuthenticated = async () => {
      const isValidSession = await checkIsValidAuthSession()

      if (isValidSession) {
        navigate(routesConfig.AUTOMATIC_CALL_PANEL, { replace: true })
      }
    }

    checkIfAuthenticated()
  })
}

export { useForbidAuthedAgentRouteHook }

import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "@tanstack/react-query"
import { useAtomValue, useSetAtom } from "jotai"
import { RESET } from "jotai/utils"

import { useCallReportValidationError } from "@/components/ACWForm/report-error-card"
import { Timer } from "@/components/CallPanel/hook"
import * as callViewValue from "@/components/CallView/value"
import { pseudoACWAtom } from "@/helpers/atoms"
import { CallReportError } from "@/helpers/error"
import { useCallDurationData } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"
import { contactStorageService } from "@/services/localStorageService"

interface ACWButtonsHookInput {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  isInboundCall: boolean
  timer: Timer | null
}

interface ACWButtonsHookType {
  isLoading: boolean
  isPseudoACW: boolean
  isVoiceContact: boolean
  onClick: (callAttempted: boolean, isManualCall?: boolean) => Promise<void>
}

const useACWButtonsHook = ({
  currentCase,
  isInboundCall,
  timer,
}: ACWButtonsHookInput): ACWButtonsHookType => {
  const isPseudoACW = useAtomValue(pseudoACWAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { pushCallReportError, resetCallReportErrors } =
    useCallReportValidationError()
  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const { t } = useTranslation()

  const { closeContact, endTask } = useConnectActionsHook()
  const cleanUp = useCleanUpManualCalling()

  const { hasTask, isVoiceContact } = useContactDataHook(currentCase)
  const sendCallReportMutation = useSendCallReport({
    currentCase,
    timer,
    isInboundCall,
  })

  const sendCallReport = async (
    validData: callViewValue.Decoder.DecoderType,
    callAttempted: boolean,
  ) => {
    try {
      await sendCallReportMutation.mutateAsync({
        formData: validData,
        callReportType: callAttempted ? "attempted-call" : "automatic-pre-call",
      })
    } catch (error) {
      // TODO: On failure display an error message to the agent before allowing the agent to
      // save the contact details and click again to move on to the next contact
      // See onClick function below
      if (error instanceof CallReportError) {
        log.error(error, { callReportData: error.callReportData })
      } else {
        log.error(error)
      }
    }
  }

  const cleanupPersistedState = () => {
    try {
      // Cleanup the contact state in local storage after the call report was posted
      cleanUp()
    } catch (err) {
      if (err instanceof Error) {
        log.error(
          `Voice call ACW: Failed to clean up manual call state: ${err.message}`,
        )
      }
    }
  }

  // Send call report and move to the next contact in the queue
  const onClick = async (callAttempted = true) => {
    setIsLoading(() => true)

    const rawSavedReport = contactStorageService.getCallReport(true)
    const validData = callViewValue.Decoder.schema.safeParse(rawSavedReport)

    if (!validData.success) {
      pushCallReportError(validData.error)
      setIsLoading(() => false)

      return
    }

    await sendCallReport(validData.data, callAttempted)
    resetCallReportErrors()
    cleanupPersistedState()

    try {
      if (isVoiceContact) {
        await closeContact()
      }
    } catch (err) {
      if (err instanceof Error) {
        log.error(`Voice call ACW: Failed to close contact: ${err.message}`)
      }

      toastError(t("errors.callPanel.nextContactError"))
    }

    try {
      if (hasTask) {
        await endTask()
      }
    } catch (err) {
      if (err instanceof Error) {
        log.error(
          `Voice call ACW: Failed to move to the next task due to error: ${err.message}`,
        )
      }

      toastError(t("errors.callPanel.nextContactError"))
    }

    setIsLoading(() => false)
  }

  return {
    isLoading,
    onClick,
    isPseudoACW,
    isVoiceContact,
  }
}

function useCleanUpManualCalling() {
  const setPseudoACW = useSetAtom(pseudoACWAtom)
  const { resetInManualCall } = useManualCallAtom()
  const { clearCallDurationData } = useCallDurationData()

  const queryClient = useQueryClient()

  const cleanUp = () => {
    // Cleanup atoms with storage
    setPseudoACW(RESET)
    resetInManualCall()
    clearCallDurationData()

    // Cleanup local storage
    contactStorageService.removeCallReport()
    contactStorageService.removeAgentHasCalled()

    // Invalidate queries
    queryClient.invalidateQueries({
      queryKey: ["/case"],
    })
  }

  return cleanUp
}

export { useACWButtonsHook, useCleanUpManualCalling }

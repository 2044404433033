import { useTranslation } from "react-i18next"
import { statusUsageMap } from "@repo/core/models"

import { MultiCombobox } from "@/components/ui/multi-combobox"
import { resolveLanguage } from "@/helpers/i18n"
import { useCallStatuses } from "@/hooks/call-statuses-context"

import { LoadingFilter } from "../pickers/generic-filter-picker"

type Props = {
  allowReset?: boolean
  onChange: (values: string[]) => void
  values: string[]
}

export function StatusPicker({ allowReset, onChange, values }: Props) {
  const { t } = useTranslation()
  const { getStatusesByUseCase, isLoading } = useCallStatuses()
  const language = resolveLanguage()

  if (isLoading) return <LoadingFilter />

  const statuses = getStatusesByUseCase(statusUsageMap.campaignDetailsDropdown)

  const options =
    statuses.map((status) => ({ value: status.id, label: status[language] })) ||
    []

  return (
    <MultiCombobox
      allowReset={allowReset}
      values={values}
      onChange={onChange}
      options={options}
      placeholder={t("campaigns.details.search.statuses")}
      size="lg"
    />
  )
}

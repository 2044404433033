import { useEffect, useState } from "react"
import { useAtom, useAtomValue } from "jotai"

import { useErrorToast } from "@/components/CallPanel/call-error-card"
import {
  agentAtom,
  agentStatusNameAtom,
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
  modalModeAtom,
  pseudoACWAtom,
} from "@/helpers/atoms"
import { pseudoACWStatus } from "@/helpers/error"
import { getContactPhoneNumber } from "@/helpers/phoneNumber"
import { hasValue } from "@/helpers/typeguards"
import { Timer, useCallDuration } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { CallReportType } from "@/hooks/useSendCallReport"
import { contactStorageService } from "@/services/localStorageService"
import { ModalMode } from "@/types/modes"

interface Initializing {
  current: "initializing"
}

interface ManualCallIdle {
  current: "manual-call-idle"
}

interface Connecting {
  current: "connecting"
  state: {
    endTimer: VoidFunction
    isInboundCall: boolean
    startTimer: VoidFunction
  }
}

interface ConnectedCall {
  current: "connected-call"
  state: {
    endTimer: VoidFunction
    isInboundCall: boolean
    startTimer: VoidFunction
  }
}

interface ConnectedTask {
  current: "connected-task"
  state: {
    callReportType: CallReportType
  }
}

interface ACWState {
  current: "acw"
  state: {
    contactType: connect.ContactType | null
    isInboundCall: boolean
  }
}

type ErrorStateMessage =
  | "internal_error"
  | "missed_call_agent_state" // e.g. due to networking error
  | "missed_call"
  | "missed_task"
  | "rejected_call"
  | "rejected_task"

interface ErrorState {
  current: "error"
  state: {
    message: ErrorStateMessage
  }
}

type DisplayMode =
  | ACWState
  | ConnectedCall
  | ConnectedTask
  | Connecting
  | ErrorState
  | Initializing
  | ManualCallIdle

interface CallPanelHookType {
  campaignName: string
  displayMode: DisplayMode
  getHasCalled: () => boolean
  isMissedTask: boolean
  modalMode: ModalMode | null
  phoneNumber: string | null
  setHasCalled: (hasCalled: boolean) => void
  shouldDisplayCounter: boolean
  switchCallReportMode: (value: CallReportType) => void
  timer: Timer | null
}

const useCallPanelHook = ({
  campaignName,
  isManualCall,
  phoneNumber,
}: {
  campaignName: string
  isManualCall?: boolean
  phoneNumber?: string
}): CallPanelHookType => {
  const agent = useAtomValue(agentAtom)
  const agentStatusName = useAtomValue(agentStatusNameAtom)
  const [isPseudoACW, setPseudoACW] = useAtom(pseudoACWAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const modalMode = useAtomValue(modalModeAtom)

  const [hasCalledCurrentContact, setHasCalledCurrentContact] =
    useState<boolean>(false)

  const defaultState: DisplayMode["current"] = isManualCall
    ? "manual-call-idle"
    : "initializing"

  const [displayMode, setDisplayMode] = useState<DisplayMode>({
    current: defaultState,
  })

  const { pushCallError } = useErrorToast()
  const { closeContact } = useConnectActionsHook()
  const { endTimer, startTimer, timer } = useCallDuration()
  const log = useCallPanelLogger()

  const voiceContact = agent?.getContacts(connect.ContactType.VOICE)[0]
  const isInboundVoiceCall = voiceContact?.isInbound() ?? false
  const missedCallAgentState = agentStatusName === "MissedCallAgent"
  // missedCallAgentState is true when Agent missed a phone call due to timer expiry
  // OR even when no incoming calls, every 30 seconds streams library polls and if due to networking issues
  // Amazon CloudFront fails return a response to the getAgentSnapshot API call, this state will be reached.
  // NOTE: this state is related to agent snapshot, so is not equivalent to when a task's ContactStateType.MISSED
  // ("missed") which indicates "Indicates the contact timed out before the agent could accept it."
  const isCallError =
    hasValue(voiceContact) &&
    hasValue(agentStatusName) &&
    pseudoACWStatus.includes(agentStatusName) &&
    !isInboundVoiceCall

  const isMissedTask =
    displayMode.current === "error" &&
    displayMode.state.message === "missed_task"

  const isSkippingCall =
    displayMode.current === "connected-task" &&
    displayMode.state.callReportType === "automatic-pre-call"

  const isNetworkConnectionError =
    displayMode.current === "error" &&
    displayMode.state.message === "missed_call_agent_state"

  const shouldDisplayCounter =
    !["initializing", "error"].includes(displayMode.current) && !isSkippingCall

  const isAfterCallWorkState = displayMode.current === "acw"

  // Store in local storage whether the agent has made an outbound call to the contact so that we no longer
  // show the Skip & Call buttons when auto-closing the task on destroy
  const setHasCalled = (hasCalled: boolean): void => {
    contactStorageService.setAgentHasCalled(hasCalled)
    setHasCalledCurrentContact(hasCalled)
  }

  const getHasCalled = (): boolean => {
    if (!hasCalledCurrentContact) {
      const storageValue = contactStorageService.getAgentHasCalled()

      return !!storageValue
    }

    return hasCalledCurrentContact
  }

  // Set the display mode based on the current contact status and whether
  // the call failed to connect
  useEffect(
    () => {
      let newMode: DisplayMode | null

      if (isCallError || isPseudoACW) {
        // call failed to connect
        if (isCallError) {
          setPseudoACW(true)
        }

        newMode = {
          current: "acw",
          state: {
            isInboundCall: isInboundVoiceCall,
            contactType: currentContactType,
          },
        }

        return setDisplayMode(newMode)
      }

      switch (currentContactStatusType) {
        case "incoming":
        case "connecting":
          newMode =
            currentContactType === connect.ContactType.VOICE
              ? {
                  current: "connecting",
                  state: {
                    endTimer,
                    startTimer,
                    isInboundCall: isInboundVoiceCall,
                  },
                }
              : { current: "initializing" }
          break

        case "connected":
          if (currentContactType === connect.ContactType.VOICE) {
            newMode = {
              current: "connected-call",
              state: {
                startTimer,
                endTimer,
                isInboundCall: isInboundVoiceCall,
              },
            }
          } else {
            if (displayMode.current === "connecting") {
              newMode = {
                current: "acw",
                state: {
                  isInboundCall: isInboundVoiceCall,
                  contactType: currentContactType,
                },
              }
              setPseudoACW(true)
            } else if (displayMode.current !== "acw") {
              newMode = {
                current: "connected-task",
                state: {
                  callReportType: "attempted-call",
                },
              }
            } else {
              // No transition from the ACW state to avoid showing the call buttons
              // during a split second just after the call report is submitted
              newMode = null
            }
          }
          break

        case "missed":
          // This occurs when current contact is in MISSED state
          newMode = {
            current: "error",
            state: {
              // TODO: Post MVP, when supporting Chat or other contact types we need to revise this condition
              message:
                currentContactType === connect.ContactType.VOICE
                  ? "missed_call"
                  : "missed_task",
            },
          }
          break

        case "rejected":
          newMode = {
            current: "error",
            state: {
              message:
                currentContactType === connect.ContactType.VOICE
                  ? "rejected_call"
                  : "rejected_task",
            },
          }
          break

        case "error": {
          // if we are in an inbound call and do not pick up in 30 seconds isInboundVoiceCall is true,
          // we have a missed call
          let stateName: ErrorStateMessage
          if (isInboundVoiceCall && missedCallAgentState) {
            stateName = "missed_call"
          } else if (missedCallAgentState) {
            // Otherwise we if in missedCallAgentState we know it must be either due to networking error
            // or we also see this if you start a call but cannot connect (due to lack of microphone access)
            // and wait 30 seconds; after 30 seconds, amazon publishes the state to missed call.
            // In the case of lack of microphone error, we catch it already separately in softphone error
            //  handler and show a modal informing user to check their microphone permissions.
            stateName = "missed_call_agent_state"
          } else {
            stateName = "internal_error"
          }

          newMode = {
            current: "error",
            state: {
              message: stateName,
            },
          }
          break
        }

        case "ended":
          newMode = {
            current: "acw",
            state: {
              isInboundCall: isInboundVoiceCall,
              contactType: currentContactType,
            },
          }
          break

        case "init":
        case "pending":
        default:
          newMode = {
            current: defaultState,
          }
      }
      if (newMode) setDisplayMode(newMode)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentContactType,
      currentContactStatusType,
      hasCalledCurrentContact,
      isInboundVoiceCall,
      missedCallAgentState,
      isCallError,
      isPseudoACW,
    ],
  )

  // Stop timer when the student ends the call
  useEffect(() => {
    if (isAfterCallWorkState) {
      endTimer()
    }
  }, [isAfterCallWorkState]) // eslint-disable-line react-hooks/exhaustive-deps

  const switchCallReportMode = (value: CallReportType): void => {
    setDisplayMode({
      current: "connected-task",
      state: { callReportType: value },
    })
  }

  // When the outbound voice call connection is lost for 30 seconds and the call is put into missed state
  // we close the contact and show an error card.
  useEffect(() => {
    if (isNetworkConnectionError) {
      try {
        pushCallError("missed_call_agent_state")
        closeContact()
        endTimer()
      } catch (error) {
        log.error(error)
      }
    }
  }, [isNetworkConnectionError]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    getHasCalled,
    setHasCalled,
    displayMode,
    modalMode,
    phoneNumber: phoneNumber || getContactPhoneNumber(agent),
    isMissedTask,
    shouldDisplayCounter,
    campaignName,
    switchCallReportMode,
    timer,
  }
}

export {
  type DisplayMode,
  type ErrorStateMessage,
  type Timer,
  useCallPanelHook,
}

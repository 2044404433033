import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"

import * as layoutValue from "@/components/layout/value"
import { findCurrentContact, getTaskAttributes } from "@/helpers/contact"
import { getContactPhoneNumber } from "@/helpers/phoneNumber"
import { CallDurationData } from "@/helpers/zodSchemas"
import { contactStorageService } from "@/services/localStorageService"
import { AuthenticationStatus, CCPMode, ModalMode } from "@/types/modes"

export enum UndocumentedAgentStateType {
  ERROR = "error",
  SYSTEM = "system",
}

export const cognitoAuthStatusAtom = atom<AuthenticationStatus>({
  current: "AUTHENTICATING",
})

export const ccpModeAtom = atom<CCPMode>({ current: "LOADING" })

export const modalModeAtom = atom<ModalMode>(null)

export const pseudoACWAtom = atomWithStorage<boolean>(
  contactStorageService.getPseudoACWKey(),
  false,
  undefined,
  { getOnInit: true },
)

export const inManualCallAtom = atomWithStorage<boolean>(
  contactStorageService.getManualCallKey(),
  false,
  undefined,
  { getOnInit: true },
)

export const agentAtom = atom<connect.Agent | null>(null)

export const agentProfileAtom =
  atom<layoutValue.Decoder.ConnectUserInformation | null>(null)

export const currentContactIdAtom = atom<string | null>(null)

export const agentStatusTypeAtom = atom<
  connect.AgentStateType | UndocumentedAgentStateType
>((get) => {
  const agent = get(agentAtom)
  const agentType = agent?.getStatus().type

  return agentType ?? connect.AgentStateType.INIT
})

export const agentStatusNameAtom = atom<string | null>((get) => {
  const agent = get(agentAtom)
  const agentName = agent?.getStatus().name

  return agentName ?? null
})

export const taskReferencesAtom = atom<connect.ReferenceDictionary | null>(
  (get) => {
    const agent = get(agentAtom)

    if (!agent) {
      return null
    }

    const tasks = agent.getContacts(connect.ContactType.TASK)

    if (!tasks.length) {
      return null
    }

    return tasks[0].getReferences()
  },
)

export const isMutedAtom = atom<boolean>(false)

export const isOnHoldAtom = atom<boolean>((get) => {
  const agent = get(agentAtom)

  if (!agent) {
    return false
  }

  const contacts = agent.getContacts(connect.ContactType.VOICE)

  if (!contacts.length) {
    return false
  }

  const activeConnection = contacts[0]?.getInitialConnection()

  if (!activeConnection) {
    return false
  }

  return activeConnection.isOnHold()
})

export const caseIdAtom = atom<string | null>((get) => {
  const agent = get(agentAtom)
  const taskAttributes = getTaskAttributes(agent)

  if (!taskAttributes) {
    return null
  }

  const caseId = taskAttributes?.case_id?.value

  return caseId ?? null
})

/**
 * Return phone number from the provided task or inbound call.
 * If no agent, return null
 * If task, retrieve number from customer_phone_number attribute
 * If an inbound call has no number we can retrieve, return undefined.
 */
export const phoneNumberAtom = atom<string | null>((get) => {
  const agent = get(agentAtom)

  if (!agent) {
    return null
  }

  const taskAttributes = getTaskAttributes(agent)

  if (!taskAttributes) {
    const phoneNumber = getContactPhoneNumber(agent)

    return phoneNumber ?? null
  }

  const phoneNumber = taskAttributes?.customer_phone_number?.value

  return phoneNumber ?? null
})

export const campaignNameAtom = atom<string | null>((get) => {
  const agent = get(agentAtom)
  const foundFirstContact = agent?.getContacts()[0]
  const queue = foundFirstContact?.getQueue()

  return queue?.name ?? null
})

export const currentContactTypeAtom = atom<connect.ContactType | null>(
  (get) => {
    const currentContactId = get(currentContactIdAtom)
    const agent = get(agentAtom)

    if (!currentContactId || !agent) {
      return null
    }

    const foundContact = findCurrentContact({ agent, currentContactId })

    return foundContact?.getType() ?? null
  },
)

export const currentContactStatusTypeAtom =
  atom<connect.ContactStateType | null>((get) => {
    const currentContactId = get(currentContactIdAtom)
    const agent = get(agentAtom)

    if (!currentContactId || !agent) {
      return null
    }

    const foundContact = findCurrentContact({ agent, currentContactId })
    const status = foundContact?.getStatus()

    return status?.type ?? null
  })

export const callDurationDataAtom = atomWithStorage<CallDurationData | null>(
  contactStorageService.getCallDurationKey(),
  null,
  undefined,
  { getOnInit: true },
)

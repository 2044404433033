import { useLifecycles } from "react-use"
import { useAtomValue } from "jotai"

import { cognitoAuthStatusAtom } from "@/helpers/atoms"
import { useAuthHook } from "@/hooks/auth"
import { AuthenticationStatus } from "@/types/modes"

// TODO: Setting the mode should be a state machine instead.
// Replace with an useReducer maybe
type UseProtectedRouteHookType = {
  cognitoAuthStatus: AuthenticationStatus
}

const useProtectedRouteHook = (): UseProtectedRouteHookType => {
  const cognitoAuthStatus = useAtomValue(cognitoAuthStatusAtom)
  const { checkAuth } = useAuthHook()

  // Validate access token on page render and start a timer to refresh it
  // useEffect with cleanup function
  useLifecycles(() => checkAuth())

  return { cognitoAuthStatus }
}

export { useProtectedRouteHook }

import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"

import { QueuePicker } from "../pickers/queue-picker"
import { CreateEditPageLayout } from "../shared/create-edit-page-layout"
import { useCreateCampaignState } from "./edit-filters-hooks"
import { FiltersPanel } from "./filters-panel"
import { PreviewPanel } from "./preview-panel"
import { PriorityFiltersPanel } from "./priority-filters-panel"

export function CreateCampaignPage() {
  const { t } = useTranslation()
  const [queue, setQueue] = useState<string | null>(null)

  const {
    filtersState,
    handleSave,
    hasFilters,
    mutation,
    priorityFiltersState,
  } = useCreateCampaignState(queue)

  return (
    <CreateEditPageLayout
      header={t("campaigns.create.title")}
      actionBar={
        <ActionBar
          isSaving={mutation.isPending}
          onSave={queue && hasFilters ? handleSave : undefined}
          queue={queue}
          setQueue={setQueue}
        />
      }
      filtersArea={<FiltersPanel campaign_id={queue} {...filtersState} />}
      previewArea={
        <PreviewPanel
          filters={filtersState.filters}
          priorityFilters={priorityFiltersState.filters}
        />
      }
      priorityFiltersArea={
        <PriorityFiltersPanel
          campaign_id={queue}
          {...priorityFiltersState}
          filters={filtersState.filters}
          priorityFilters={priorityFiltersState.filters}
          setPriorityFilters={priorityFiltersState.setFilters}
        />
      }
    />
  )
}

function ActionBar({
  isSaving,
  onSave,
  queue,
  setQueue,
}: {
  isSaving: boolean
  onCancel?: () => void
  onSave?: () => void
  queue: string | null
  setQueue: (queue: string) => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-4">
      <QueuePicker value={queue} onChange={setQueue} />
      <Button
        variant="default"
        isDisabled={!onSave || isSaving}
        onClick={onSave}
        isLoading={isSaving}
      >
        {t("global.button.save")}
      </Button>
    </div>
  )
}

import { useTranslation } from "react-i18next"
import { PlusIcon, SlidersHorizontalIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

import { FilterSelections, FiltersUpdateFn } from "../campaigns-types"
import { ChannelsControl } from "../channels/channels-control"
import { UniversityFilterControl } from "../university/universities-filter-control"
import { useFilterState } from "./edit-filters-hooks"
import { FilterPicker } from "./filter-picker"
import { FilterControl, FilterPanelLayout } from "./filters-panel-shared"
import { isFilterSelectionsEmpty, useFiltersPanelState } from "./filters-state"

type Props = Omit<
  ReturnType<typeof useFilterState>,
  "filters" | "setFilters"
> & {
  campaign_id: string | null
  filters?: FilterSelections
  priorityFilters?: FilterSelections
  setPriorityFilters: FiltersUpdateFn
}

export function PriorityFiltersPanel(props: Props) {
  const { filters, priorityFilters, setPriorityFilters } = props
  const { t } = useTranslation()

  const {
    areAllFiltersHidden,
    availableFiltersToAdd,
    handleAddFilter,
    handleEditChannels,
    handleEditUniversities,
    handleResetFilter,
    isFilterVisible,
  } = useFiltersPanelState({
    ...props,
    isPriorityFilter: true,
    filters: priorityFilters || {},
    restrictionFilters: filters,
    setFilters: setPriorityFilters,
  })

  return (
    <FilterPanelLayout
      isEmpty={areAllFiltersHidden}
      header={
        <>
          <SlidersHorizontalIcon className="size-6" />
          {t("campaigns.priorityFilters.title")}
        </>
      }
      addButton={
        <FilterPicker
          availableFilters={availableFiltersToAdd}
          onChange={handleAddFilter}
          showBasicFilters={false}
        >
          <Button
            variant="link"
            className="h-auto gap-0 p-0"
            size="sm"
            isDisabled={availableFiltersToAdd.length === 0}
          >
            <PlusIcon className="size-4" />
            {t("campaigns.filters.add.short")}
          </Button>
        </FilterPicker>
      }
      emptyState={
        !isFilterSelectionsEmpty(filters || {}) ? (
          <FilterPicker
            availableFilters={availableFiltersToAdd}
            onChange={handleAddFilter}
            showBasicFilters={false}
          >
            <Button variant="soft" className="w-campaign-filter">
              {t("campaigns.filters.add.long")}
            </Button>
          </FilterPicker>
        ) : (
          <div className="w-campaign-filter rounded-md border p-4 text-sm italic text-muted-foreground">
            {t("campaigns.priorityFilters.warningNoFilters")}
          </div>
        )
      }
    >
      {isFilterVisible("channels") && (
        <FilterControl
          label={t("campaigns.filters.channels.label")}
          onReset={handleResetFilter("channels")}
        >
          <ChannelsControl
            channels={priorityFilters?.channels || []}
            onEdit={handleEditChannels}
          />
        </FilterControl>
      )}
      {isFilterVisible("university") && (
        <FilterControl
          label={t("campaigns.filters.university.label")}
          onReset={handleResetFilter("university")}
        >
          <UniversityFilterControl
            filters={priorityFilters?.university || []}
            onEdit={handleEditUniversities}
          />
        </FilterControl>
      )}
    </FilterPanelLayout>
  )
}

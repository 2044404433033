import { useModal } from "@/components/core/Root/modal-root"
import { isArray } from "@/helpers/typeguards"

import { DialogAddStudents } from "../add-student/dialog-add-students"
import {
  FilterSelectionChannel,
  FilterSelections,
  FilterSelectionUniversity,
  PhoneNumberValidationResult,
} from "../campaigns-types"
import { DialogEditChannels } from "../channels/dialog-edit-channels"
import { DialogUniversityFilter } from "../university/universities-filter-control"
import { useFilterState } from "./edit-filters-hooks"

type Props = ReturnType<typeof useFilterState> & {
  campaign_id: string | null
  isPriorityFilter?: boolean
  restrictionFilters?: FilterSelections
}

export function useFiltersPanelState({
  addFilter,
  campaign_id,
  filters,
  isPriorityFilter,
  isVisibleFilter,
  removeFilterAndHide,
  removeFilterKeepVisible,
  restrictionFilters,
  setFilters,
}: Props) {
  const { showModal } = useModal()

  const allFilters: Array<keyof FilterSelections> = isPriorityFilter
    ? ["channels", "university"]
    : [
        "channels",
        "university",
        "graduation_year",
        "department_group",
        "major",
        "gender",
        "selected_phone_numbers",
      ]

  const availableFiltersToAdd = allFilters.filter(
    (key) => !isVisibleFilter(key),
  )

  async function handleEditChannels() {
    const editedChannels = await showModal<FilterSelectionChannel[]>(
      (close) => (
        <DialogEditChannels
          close={close}
          campaign_id={campaign_id}
          channels={filters.channels || []}
          restrictionChannels={restrictionFilters?.channels}
        />
      ),
    )
    if (!editedChannels) return // cancel button

    if (editedChannels.length === 0) {
      removeFilterKeepVisible("channels")
    } else {
      setFilters((current) => ({
        ...current,
        channels: editedChannels,
      }))
    }
  }

  async function handleEditUniversities() {
    const universityFilters = await showModal<FilterSelectionUniversity[]>(
      (close) => (
        <DialogUniversityFilter
          campaign_id={campaign_id}
          close={close}
          filters={filters.university || []}
          restrictionFilters={restrictionFilters?.university}
        />
      ),
    )
    if (!universityFilters) return // cancel button

    if (universityFilters.flatMap((filter) => filter.values).length === 0) {
      removeFilterKeepVisible("university")
    } else {
      setFilters((current) => ({ ...current, university: universityFilters }))
    }
  }

  async function handleEditPhoneNumbers() {
    const results = await showModal<PhoneNumberValidationResult[]>((close) => {
      return (
        <DialogAddStudents
          close={close}
          items={filters.selected_phone_numbers || []}
        />
      )
    })
    if (!results) return
    setFilters((current) => ({ ...current, selected_phone_numbers: results }))
  }

  /**
   * Channels, university and phone number have a specific behavior:
   * as we open a modal to edit them directly,
   * instead of just showing the filter button in the filter sidebar
   */
  function handleAddFilter(filterKey: keyof FilterSelections) {
    switch (filterKey) {
      case "channels":
        return handleEditChannels()
      case "selected_phone_numbers":
        return handleEditPhoneNumbers()
      case "university":
        return handleEditUniversities()
      default:
        addFilter(filterKey)
    }
  }

  const onChangeFilter =
    (propertyName: keyof FilterSelections) => (values: unknown[]) => {
      if (values.length > 0) {
        setFilters((current) => ({
          ...current,
          [propertyName]: { values },
        }))
      } else {
        // remove the filter value but keep the filter visible in the UI,
        // to avoid making dropdowns disappear when unselecting all values
        removeFilterKeepVisible(propertyName)
      }
    }

  const handleResetFilter = (filterKey: keyof FilterSelections) => () => {
    removeFilterAndHide(filterKey)
  }

  const areAllFiltersHidden = allFilters.every(
    (key) => !isVisibleFilter(key as keyof FilterSelections),
  )

  return {
    isFilterVisible: isVisibleFilter,
    availableFiltersToAdd,
    handleAddFilter,
    handleEditChannels,
    handleEditPhoneNumbers,
    handleEditUniversities,
    onChangeFilter,
    handleResetFilter,
    areAllFiltersHidden,
  }
}

export function isFilterSelectionsEmpty(filters: FilterSelections) {
  return Object.entries(filters).every(([key, value]) =>
    isFilterEmpty(key as keyof FilterSelections, value),
  )
}

function isFilterEmpty(
  filterKey: keyof FilterSelections,
  filterValue: FilterSelections[keyof FilterSelections],
) {
  switch (filterKey) {
    case "channels":
    case "selected_phone_numbers":
    case "university":
      return !filterValue || (isArray(filterValue) && filterValue?.length === 0)
    default:
      return !filterValue?.values || filterValue?.values.length === 0
  }
}

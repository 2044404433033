/* eslint-disable no-console */
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn as signInAmplify,
  signOut,
} from "@aws-amplify/auth"
import { Amplify } from "aws-amplify"
import { useSetAtom } from "jotai"

import { cognitoAuthStatusAtom } from "@/helpers/atoms"

import { config } from "../config"

type ValidInputCredentials = {
  password: string
  username: string
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.cognitoUserPoolId,
      userPoolClientId: config.cognitoClientId,
    },
  },
})

const useAuthWithAmplify = () => {
  const setCognitoAuthStatus = useSetAtom(cognitoAuthStatusAtom)

  const signIn = async (credentials: ValidInputCredentials) => {
    console.info("[amplify-auth] Signing in")
    setCognitoAuthStatus({ current: "AUTHENTICATING" })

    const response = await signInAmplify(credentials)

    return response
  }

  const checkAuth = async () => {
    try {
      const session = await fetchAuthSession()

      if (!session?.tokens?.accessToken) {
        throw new Error("No valid session found")
      }

      const accessToken = session.tokens.accessToken.toString()
      console.info(
        `[amplify-auth] Authenticated, token, ***${accessToken.slice(-5)}`,
      )
      setCognitoAuthStatus({ current: "AUTHENTICATED", state: { accessToken } })
    } catch (err) {
      setCognitoAuthStatus({ current: "NOT_AUTHENTICATED" })
    }
  }

  const checkIsValidAuthSession = async () => {
    try {
      const session = await fetchAuthSession()

      return !!session?.tokens?.accessToken
    } catch (error) {
      return false
    }
  }

  /**
   * Fetch a new token after a 401 error, when the token has expired (see Axios interceptor)
   * The session does not have to be validated in this scenario
   * */
  const refreshTokenAfterFailure = async () => {
    const session = await fetchAuthSession({ forceRefresh: true })
    const accessToken = session?.tokens?.accessToken?.toString()

    if (!accessToken) {
      throw new Error("No valid session found")
    }

    console.info(
      `[amplify-auth] Refreshed token after failure: , ***${accessToken.slice(
        -5,
      )}`,
    )
    setCognitoAuthStatus({
      current: "AUTHENTICATED",
      state: { accessToken },
    })

    return accessToken
  }

  const requestPasswordReset = async (email: string) => {
    return resetPassword({ username: email })
  }

  const changePassword = async ({
    email,
    newPassword,
    verificationCode,
  }: {
    email: string
    newPassword: string
    verificationCode: string
  }) => {
    return confirmResetPassword({
      username: email,
      confirmationCode: verificationCode,
      newPassword,
    })
  }

  const confirmPasswordForFirstSignIn = async (password: string) => {
    const result = await confirmSignIn({
      challengeResponse: password,
    })

    if (result.isSignedIn === true) {
      const session = await fetchAuthSession()
      const accessToken = session?.tokens?.accessToken?.toString()

      if (!accessToken) {
        throw new Error("No valid session found")
      }

      console.info(
        `[amplify-auth] First sign in confirmed, token, ***${accessToken.slice(
          -5,
        )}`,
      )
      setCognitoAuthStatus({ current: "AUTHENTICATED", state: { accessToken } })
    }
  }

  const getAccessToken = async (): Promise<string> => {
    const session = await fetchAuthSession()
    const accessToken = session?.tokens?.accessToken?.toString()

    if (!accessToken) {
      throw new Error("No valid session found")
    }

    return accessToken
  }

  return {
    signIn,
    checkAuth,
    confirmPasswordForFirstSignIn,
    refreshTokenAfterFailure,
    requestPasswordReset,
    changePassword,
    signOut,
    getAccessToken,
    checkIsValidAuthSession,
  }
}

export { useAuthWithAmplify }

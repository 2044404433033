import { MutableRefObject, useEffect, useRef, useState } from "react"
import { useMount, useTimeoutFn } from "react-use"
import { useAtom, useSetAtom } from "jotai"
import { RESET } from "jotai/utils"

import { useErrorToast } from "@/components/CallPanel/call-error-card"
import { useManualCallContext } from "@/components/core/ManualCallProvider"
import { config } from "@/config/index"
import {
  agentAtom,
  ccpModeAtom,
  currentContactIdAtom,
  isMutedAtom,
  modalModeAtom,
  pseudoACWAtom,
} from "@/helpers/atoms"
import { unmountIframe } from "@/helpers/ccp"
import { connectLogoutUrl, useAuthHook } from "@/hooks/auth"
import { useCallDurationData } from "@/hooks/callDuration"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"
import { contactStorageService } from "@/services/localStorageService"
import { ConnectMode } from "@/types/modes"

import {
  clearConnectTimestamp,
  setTimestampInLocalStorage,
} from "./connect_session"
import {
  initAgentEventHandlers,
  initContactEventHandlers,
  initErrorHandlers,
} from "./init_ccp"
import { SetAgent, SetContactId } from "./types"

interface ConnectCCPType {
  connectMode: ConnectMode
  hiddenFormRef: MutableRefObject<HTMLFormElement | null>
  hiddenIframeContainerRef: MutableRefObject<HTMLIFrameElement | null>
  ref: MutableRefObject<HTMLDivElement | null>
}

const ccpUrl = `https://${config.connectInstanceAlias}.my.connect.aws/connect/ccp-v2`

const initCCPOptions = {
  ccpUrl,
  region: config.awsRegion,
  loginPopup: false,
  loginOptions: {
    autoClose: true,
  },
  loginPopupAutoClose: true,
  softphone: { allowFramedSoftphone: true },
  task: { disableRingtone: true },
}

const useConnectCCPHook = (): ConnectCCPType => {
  const { getConnectCredentials } = useAuthHook()

  const { clearCallDurationData } = useCallDurationData()
  const { resetInManualCall } = useManualCallAtom()

  // React refs to the div element where the CCP iframe will be mounted
  const ref = useRef<HTMLDivElement | null>(null)
  const hiddenFormRef = useRef<HTMLFormElement | null>(null)
  const hiddenIframeContainerRef = useRef<HTMLIFrameElement | null>(null)
  // Put the agent and the contact in a ref so that Amazon Connect event handlers
  // (running outside of the react app) can access the updated values
  const contactIdRef = useRef<string | null>(null)
  const agentRef = useRef<connect.Agent | null>(null)

  const [connectMode, setConnectMode] = useState<ConnectMode>({
    current: "AUTHENTICATING",
    state: { credentials: null },
  })

  const [ccpMode, setCCPMode] = useAtom(ccpModeAtom)
  const setModalMode = useSetAtom(modalModeAtom)
  const setAgentInState = useSetAtom(agentAtom)
  const setCurrentContactIdInState = useSetAtom(currentContactIdAtom)
  const setIsMuted = useSetAtom(isMutedAtom)
  const setPseudoACW = useSetAtom(pseudoACWAtom)
  const inManualCallRef = useManualCallContext()
  const { pushCallError } = useErrorToast()

  // Hook to display a message to the agent if the initialization is slow
  const [isReady, cancel, reset] = useTimeoutFn(() => {
    if (ccpMode.current === "LOADING") {
      setCCPMode({
        current: "LOADING",
        isSlow: true,
      })
    }
  }, config.slowInitializationThreshold)

  const shouldFetchConnectCredentials = connectMode.current === "AUTHENTICATING"
  const shouldPostConnectCredentials =
    connectMode.current === "WITH_CREDENTIALS"
  const shouldInitCCPIframe = connectMode.current === "AUTHENTICATED"

  const setAgent: SetAgent = (agent) => {
    agentRef.current = agent
    setAgentInState(agent)
  }
  const setCurrentContactId: SetContactId = (contactId) => {
    contactIdRef.current = contactId
    setCurrentContactIdInState(contactId)
  }

  const resetIsMuted = () => setIsMuted(false)

  // Resets the contact's data in preparation to fetching the data for the next contact
  // in the automated call flow; Should not reset the state when in the manual call flow; manual call flow
  // will cleanup manually after posting a call report
  const resetStateForNextContact = () => {
    const isManualCall = Boolean(inManualCallRef?.current)

    if (isManualCall) {
      return
    }

    setPseudoACW(RESET)
    resetInManualCall()

    clearCallDurationData()
    contactStorageService.removeAgentHasCalled()
  }

  // Use the Amazon Connect Federated token to authenticate the CCP iframe
  // and put the federated token in state
  const getConnectFederatedToken = async () => {
    try {
      // Exchange the AWS Cognito token for an AWS Connect federated token
      const loginCredentials = await getConnectCredentials()

      setConnectMode({
        current: "WITH_CREDENTIALS",
        state: { credentials: loginCredentials },
      })

      // Store date when Amazon connect credentials were retrieved to know when it's time
      // to refresh the federated token
      setTimestampInLocalStorage()
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Unknown connect authentication error"

      setConnectMode({
        current: "NOT_AUTHENTICATED",
        state: { credentials: null, errorMessage },
      })
    }
  }

  const getConnectCookie = () => {
    if (!shouldPostConnectCredentials) {
      return
    }

    // Post credentials to the connect login route; See https://stackoverflow.com/a/4702110
    hiddenFormRef.current?.submit()

    setConnectMode({
      current: "AUTHENTICATED",
      state: { credentials: connectMode.state.credentials },
    })
  }

  // The AWS Connect access token is valid for 12 hours. We'll refresh the token after 10 hours
  // to prevent the edge case when the agent gets disconnected while in a super long call (e.g. 2 hour call).
  const refreshConnectSession = async () => {
    setCCPMode({ current: "LOADING" })

    try {
      // Sign out from Amazon Connect
      // TODO: In a future iteration, use the refresh token route instead of the logout route
      await fetch(connectLogoutUrl, { credentials: "include", mode: "no-cors" })
      clearConnectTimestamp()

      // Restart the AWS Connect authentication process; will request a new cookie with the AWS Connect access token
      setConnectMode({
        current: "AUTHENTICATING",
        state: { credentials: null },
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Unknown connect authentication error"

      setConnectMode({
        current: "NOT_AUTHENTICATED",
        state: { credentials: null, errorMessage },
      })
    }
  }

  const setInitialized = () => {
    setCCPMode({ current: "INITIALIZED" })

    // Cancel the slow-loading state after the CCP has been initialized
    if (isReady() === false) {
      cancel()
    }
  }

  const initializeCCPIframe = () => {
    const iframeContainer = ref.current

    // Set the log level; Will not mute iframe internal logs. Only the custom added logs
    // https://github.com/amazon-connect/amazon-connect-streams/issues/269
    const connectLogger = connect.getLog()
    connectLogger.setEchoLevel(connect.LogLevel.WARN)
    connectLogger.setLogLevel(connect.LogLevel.WARN)

    connect.core.initCCP(iframeContainer as HTMLElement, initCCPOptions)
    connect.core.onInitialized(setInitialized)

    // Initialize all iframe error handlers
    initErrorHandlers({ setCCPMode })

    // Initialize event handlers for agent events
    initAgentEventHandlers({
      agentRef,
      setAgent,
      setModalMode,
      refreshConnectSession,
      pushCallError,
    })

    // Initialize event handlers for contact events
    initContactEventHandlers({
      setCurrentContactId,
      agentRef,
      resetIsMuted,
      refreshConnectSession,
      resetStateForNextContact,
    })
  }

  const unmountCCPIframe = (iframeContainer: HTMLDivElement | null) => () => {
    connect.core.terminate()
    setAgent(null)
    setCurrentContactId(null)
    setCCPMode({ current: "LOADING" })
    if (isReady() === false) {
      reset() // Reset the timeout only when the timeout has not been reached
    }

    // Removes the CCP Iframe mounted inside the React App
    unmountIframe(iframeContainer)
  }

  // AUTH Steps:
  // 1. Prepare the authentication of the the Connect instance
  useMount(() => {
    const hiddenIframeDiv = hiddenIframeContainerRef.current

    if (!hiddenIframeDiv) {
      throw new Error(
        "Could not find the iframe container to initiate Connect authentication",
      )
    }

    hiddenIframeDiv.innerHTML = `<iframe src="" id="hidden_iframe" name="hidden_iframe" frameBorder="0" style="height:1px;visibility:hidden"></iframe>`

    return () => {
      hiddenIframeDiv.innerHTML = ""
    }
  })

  // 2. Get the Amazon Connect Federation token
  useEffect(() => {
    if (!shouldFetchConnectCredentials) {
      return
    }

    getConnectFederatedToken()
  }, [shouldFetchConnectCredentials]) // eslint-disable-line react-hooks/exhaustive-deps

  // 3. Post the Amazon Connect credentials to the Connect login route and retrieve a cookie in the iframe
  // This second hook is required because the form.post() method
  // sends the connect credentials that are stored in state
  useEffect(() => {
    getConnectCookie()
  }, [shouldPostConnectCredentials]) // eslint-disable-line react-hooks/exhaustive-deps

  // 4. Once we have the cookie with credentials we can initialize the CCP iframe and the Customer Profiles Client (iframe)
  useEffect(() => {
    const iframeContainer = ref.current

    if (!shouldInitCCPIframe) {
      return
    }

    initializeCCPIframe()

    return unmountCCPIframe(iframeContainer)
  }, [shouldInitCCPIframe]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ref,
    hiddenFormRef,
    hiddenIframeContainerRef,
    connectMode,
  }
}

export { useConnectCCPHook }
